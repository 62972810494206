import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import drilldown from 'highcharts/modules/drilldown';
drilldown(Highcharts);
HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

@Component({
  selector: 'app-agent4',
  templateUrl: './agent4.component.html',
  styleUrls: ['./agent4.component.scss']
})
export class Agent4Component implements OnInit {

  expectedPremium = [
  
    { name: "Auto Speciality",value:"auto" },
    { name: "Home Speciality",value:"home" },
    { name: "Business Insurance Coverage 1",value:"business1"},
    { name: "Business Insurance Coverage 2",value:"business2"}
  ];
  policyId: string = '';
  columnChart: any;
  barChart: Highcharts.Chart | null = null;
  categories = ['Less than 3 Products', '3 to 5 products', '6 to 11 products', 'Above 11 products'];
  data = ['$9,694', '35%', '$6,872', '25%', '$4,779', '22%', '$7,162', '18%'];
  constructor(private router: Router) {}
  initDonutChart() {
    const formattedData = this.formatData(this.data);

    Highcharts.chart('donutChartContainer', {
      chart: {
        type: 'pie',
      },
      title: {
        text: '',
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.0f}%',
            distance: -20,
            style: {
              fontSize: '12px',
            },
          },
          showInLegend: true,
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
        
      },
      exporting: {
        enabled: false, // Disable the exporting module
      },
      series: [
        {
          type: 'pie',
          innerSize: '60%',
          data: formattedData,
        },
      ],
    });
  }

  private formatData(data: string[]): any[] {
    const formattedData: any[] = [];

    for (let i = 0; i < data.length; i += 2) {
      const category = this.categories[i / 2];
      const value = data[i];
      const percentage = data[i + 1];

      formattedData.push({
        name: category,
        y: parseInt(percentage.replace('%', ''), 10),
      });
    }

    return formattedData;
  }

  initLineChart() {
    const chartOptions: Highcharts.Options = {
      title: {
        text: '',
      },
      xAxis: {
        categories: ['Jun-2020', 'Jul-2020', 'Jan 2021', 'Feb-2021'],
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: false, // Disable the exporting module
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true, // Enable data labels
          },
          showInLegend: false,
        },
      },
      series: [
        {
          type: 'line',
          name: 'Series 1',
          data: [433323, 265876, 56700, 10000],
        },
      ],
      credits: {
        enabled: false, // Disable the credits (Highcharts.com attribution)
      },
    };

    Highcharts.chart('lineChartContainer', chartOptions);
  }

  gwdLineChart() {
    const chartOptions: Highcharts.Options = {
      title: {
        text: '',
      },
      xAxis: {
        categories: ['Jun-2020', 'Jul-2020', 'Jan 2021', 'Feb-2021'],
      },
      yAxis: {
        title: {
          text: '',
        },
      },
      exporting: {
        enabled: false, // Disable the exporting module
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true, // Enable data labels
          },
          showInLegend: false,
        },
      },
      series: [
        {
          type: 'line',
          data: [133323, 793323, 45680, 1265],
          showInLegend: false,
        },
      ],
      credits: {
        enabled: false, // Disable the credits (Highcharts.com attribution)
      },
    };

    Highcharts.chart('gwdlineChartContainer', chartOptions);
  }
  initCombinationChart() {
    const chartOptions: Highcharts.Options = {
      title: {
        text: '',
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May','Jun','Jul', 'Aug','Sept','Oct', 'Nov','Dec'],
      },
     
      yAxis: [
        {
          title: {
            text: '',
          },
        },
        {
          title: {
            text: '',
          },
          opposite: true, // Display the line axis on the opposite side
        },
      ],
      legend: {
        enabled: true, // Disable the legend
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
      credits: {
        enabled: false, // Disable the credits (Highcharts.com attribution)
      },
      exporting: {
        enabled: false, // Disable the exporting module
      },
      series: [
        {
          type: 'column',
          name: '# of Leads',
          data: [92, 50, 80, 40, 25, 20,90,5,8,7,7,10],
          yAxis: 0, // Corresponds to the first y-axis
          
        },
        {
          type: 'line',
          name: '% closed & Loss',
          data: [67, 48, 50, 15, 5, 5,50,4,7,2,2,10],
          yAxis: 1, // Corresponds to the second y-axis
        },
        {
          type: 'line',
          name: '% Won',
          data: [25, 32, 30, 20, 18, 15,40,10,10,8,5,0],
          yAxis: 1, // Corresponds to the second y-axis
        },
      ],
    };

    Highcharts.chart('combinationChartContainer', chartOptions);
  }

  // initPieChart() {
  //   const chartOptions: Highcharts.Options = {
  //     chart: {
  //       type: 'pie',
        
  //     },
  //     colors: ['#777','#007BFF'],
  //     title: {
  //       text: '',
  //     },
  //     plotOptions: {
  //       pie: {
  //         allowPointSelect: true,
  //         cursor: 'pointer',
  //         dataLabels: {
  //           enabled: true,
  //           format: '{point.name}: {point.percentage:.1f}%', // Display percentage with one decimal place
  //           distance: 20,
  //         },
  //       },
  //     },
  //     credits: {
  //       enabled: false,
  //     },
  //     legend: {
  //       enabled: false,
  //     },
  //     exporting: {
  //       enabled: false,
  //     },
  //     series: [
  //       {
  //         type: 'pie',
  //         name: '',
  //         data: [
  //           {
  //             name: '$181.6M',
  //             y: 11,
  //             sliced: true,
  //             selected: true,
  //             dataLabels: {
  //               enabled: true,
  //             },
  //           },
  //           {
  //             name: 'Expected Premium',
  //             y: 89,
  //             dataLabels: {
  //               enabled: false,
  //             },
  //           },
  //         ],
  //       },
  //     ],
  //   };
  
  //   Highcharts.chart('pieChartContainer', chartOptions);
  // }

  initPieChart() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'pie',
        
      },
      colors: ['#777','#007BFF'],
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}%', // Display percentage with one decimal place
            distance: 20,
          },
        },
      },
      credits: {
        enabled: false,
      },
      legend: {
        enabled: true,
      },
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'pie',
          name: '',
          data: [
            {
              name: 'Expected Premium - $181.6M',
              y: 10.7,
              sliced: true,
              selected: true,
              dataLabels: {
                enabled: false,
              },
            },
            {
              name: '',
              y: 89.3,
              dataLabels: {
                enabled: false,
              },
            },
          ],
        },
      ],
    };
  
    Highcharts.chart('pieChartContainer', chartOptions);
  }


  initColumnChartFirst1() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['Expected Premium'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
          formatter: function () {
            const value = parseFloat(this.axis.defaultLabelFormatter.call(this) as string);
            return '$' + (parseFloat(Highcharts.numberFormat(value, 2, '.', ',')) / 1000000).toFixed(2) + 'M';
          },
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '${point.y:.2f}M',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [25.1],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [30.7],
        
        },
        
      ],
    };

    Highcharts.chart('columnChartContainer', chartOptions);
  }
  initColumnChartFirst2() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['Expected Premium'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
          formatter: function () {
            const value = parseFloat(this.axis.defaultLabelFormatter.call(this) as string);
            return '$' + (parseFloat(Highcharts.numberFormat(value, 2, '.', ',')) / 1000000).toFixed(2) + 'M';
          },
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '${point.y:.2f}M',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [5.2],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [10.9],
        
        },
        
      ],
    };

    Highcharts.chart('columnChartContainer', chartOptions);
  }
  initColumnChartFirst3() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['Expected Premium'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
          formatter: function () {
            const value = parseFloat(this.axis.defaultLabelFormatter.call(this) as string);
            return '$' + (parseFloat(Highcharts.numberFormat(value, 2, '.', ',')) / 1000000).toFixed(2) + 'M';
          },
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '${point.y:.2f}M',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [15.3],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [59.4],
        
        },
        
      ],
    };

    Highcharts.chart('columnChartContainer', chartOptions);
  }
  initColumnChartFirst4() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['Expected Premium'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
          formatter: function () {
            const value = parseFloat(this.axis.defaultLabelFormatter.call(this) as string);
            return '$' + (parseFloat(Highcharts.numberFormat(value, 2, '.', ',')) / 1000000).toFixed(2) + 'M';
          },
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '${point.y:.2f}M',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [8.9],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [26.1],
        
        },
        
      ],
    };

    Highcharts.chart('columnChartContainer', chartOptions);
  }






  initColumnChartSecond1() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['#of Opportunities'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
         
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [940],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [2510],
        
        },
        
      ],
    };
    Highcharts.chart('columnChartContainer2', chartOptions);
  }
  initColumnChartSecond2() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['#of Opportunities'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
         
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [3731],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [1110],
        
        },
        
      ],
    };
    Highcharts.chart('columnChartContainer2', chartOptions);
  }
  initColumnChartSecond3() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['#of Opportunities'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
         
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [2411],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [2019],
        
        },
        
      ],
    };
    Highcharts.chart('columnChartContainer2', chartOptions);
  }
  initColumnChartSecond4() {
    const chartOptions: Highcharts.Options = {
      chart: {
        type: 'column',
        
      },
      colors: ['#F88379','#007BFF'],

      title: {
        text: '',
      },
      xAxis: {
        categories: ['#of Opportunities'],
        // categories: ['Expected Premium', '#of Opportunities'],

        labels: {
          style: {
            fontSize: '8px', // Adjust the font size as needed
          },
        },
      },
      yAxis: {
        title: {
          text: '',
        },
        
        labels: {
          enabled: false,
         
          
        },
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            format: '{point.y}',
            style: {
              fontSize: '9px', // Font size for data labels
            },
          },
          
          grouping: false,
          stacking: 'normal',
        },
      },
      credits: {
        enabled: false,
      },

      legend: {
        enabled: true,
        itemStyle: {
          fontSize: '10px', // Set the font size for series names
        },
      },
     
      exporting: {
        enabled: false,
      },
      series: [
        {
          type: 'column',
          name: 'Other Leads',
          data: [2963],
         
        },
        {
          type: 'column',
          name: 'Hot Leads',
          data: [663],
        
        },
        
      ],
    };
    Highcharts.chart('columnChartContainer2', chartOptions);
  }

 
  ngOnInit(): void {
   
    this.initDonutChart();
    this.initLineChart();
    this.gwdLineChart();
    this.initCombinationChart();
    this.initPieChart();
    this.initColumnChartFirst1();
    this.initColumnChartSecond1();
    

  }

  changeChart(deviceValue){
    if(deviceValue=='auto'){this.initColumnChartFirst1();this.initColumnChartSecond1();}
    else if(deviceValue=='home'){this.initColumnChartFirst2();this.initColumnChartSecond2();}
    else if(deviceValue=='business1'){this.initColumnChartFirst3();this.initColumnChartSecond3();}
    else if(deviceValue=='business2'){this.initColumnChartFirst4();this.initColumnChartSecond4();}
   
    
  }
  searchPolicy(policyId: string) {
    const lowerCasePolicyId = policyId.toLowerCase();
  
    if (policyId === 'POL/2023/AUTO/83485' || lowerCasePolicyId === 'pol/2023/auto/83485'|| lowerCasePolicyId === 'Pol/2023/Auto/83485') {
      window.open('csr/auto-personal-linesA', '_blank');
    }
    if (policyId === 'POL/2023/AUTO/83486' || lowerCasePolicyId === 'pol/2023/auto/83486' || lowerCasePolicyId === 'Pol/2023/Auto/83486') {
      window.open('csr/auto-personal-linesB', '_blank');
    }
    if (policyId === 'POL/2023/AUTO/83487' || lowerCasePolicyId === 'pol/2023/auto/83487'|| lowerCasePolicyId === 'Pol/2023/Auto/83487') {
      window.open('csr/auto-personal-linesC', '_blank');
    }
    if (policyId === 'POL/2023/HOME/83484' || lowerCasePolicyId === 'pol/2023/home/83484'|| lowerCasePolicyId === 'Pol/2023/Home/83484') {
      window.open('csr/auto-personal-linesE', '_blank');
    }
    if (policyId === 'POL/2023/HOME/83488' || lowerCasePolicyId === 'pol/2023/home/83488'|| lowerCasePolicyId === 'Pol/2023/Home/83488') {
      window.open('csr/auto-personal-linesD', '_blank');
    }
    if (policyId === 'POL/2023/S-HOME/83489' || lowerCasePolicyId === 'pol/2023/s-home/83489'|| lowerCasePolicyId === 'Pol/2023/S-Home/83489') {
      window.open('csr/auto-personal-linesF', '_blank');
    }
  }

}
